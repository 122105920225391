import { Controller } from 'stimulus';
import { useDebounce } from 'stimulus-use';

import coopenerEmailRegex from '@/shared/coopener_email_regex.js';

export default class extends Controller {
  static targets = ['email', 'submitButton'];

  static outlets = ['reveal']

  static debounces = [{ name: 'checkForEmail', wait: 350 }];

  static values = {
    checkforemailparam: String,
  };

  connect() {
    useDebounce(this);
  }

  checkForEmail(e) {
    if (this.emailTarget.value.match(coopenerEmailRegex)) {
      this.emailTarget.readOnly = true;
      this.toggleElements(e);
      this.verifyEmail();
    }
  }

  toggleElements(e) {
    this.revealOutlets.forEach((reveal) => reveal.toggle(e));
  }

  async verifyEmail() {
    this.submitButtonTarget.disabled = true;
    const form = this.element.form || this.element.closest('form');
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = this.checkforemailparamValue;
    hiddenInput.value = 'true';
    form.appendChild(hiddenInput);
    form.setAttribute('data-turbo-action', 'replace');
    form.requestSubmit();
  }
}
