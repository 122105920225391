import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

window.Stimulus = Application.start();

// Register Stimulus controllers found in `app/frontend/controllers` and `app/components`
const stimulusControllers = import.meta.glob('../controllers/**/*_controller.js', { eager: true });
const stimulusComponentControllers = import.meta.glob('../../components/**/*_controller.js', { eager: true });

registerControllers(window.Stimulus, stimulusControllers);
registerControllers(window.Stimulus, stimulusComponentControllers);
