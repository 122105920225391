import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    paramName: String,
    paramValue: String,
  };

  submit(e) {
    // Without preventing default for `e`, turbo was unable to preserve scroll
    e.preventDefault();
    const form = this.element.form || this.element.closest('form');
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = this.paramNameValue;
    hiddenInput.value = this.paramValueValue;
    form.appendChild(hiddenInput);
    form.requestSubmit();
  }
}
