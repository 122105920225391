import { Controller } from 'stimulus';

import coopenerEmailRegex from '@/shared/coopener_email_regex.js';

export default class extends Controller {
  static targets = ['email', 'alert', 'alreadyClient', 'password', 'markVisibility', 'modalVisibility'];

  static values = {
    loadingtext: String,
    alreadytext: String,
  }

  controller = null;

  checkForEmail() {
    if (this.emailTarget.value.match(coopenerEmailRegex)) {
      this.loginConnection();
    }
  }

  async loginConnection() {
    this.abortPreviousFetch();

    this.controller = new AbortController();

    const response = await fetch(
      '/api/v1/login/connect',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        },
        body: JSON.stringify(
          {
            login: {
              email: this.emailTarget.value,
              password: this.passwordTarget.value,
            },
          },
        ),
      },
      { signal: this.controller.signal },
    );

    if (response.ok) {
      this.setAlreadyClientConnected();
    } else {
      this.sendErrorMsg();
    }
  }

  abortPreviousFetch() {
    if (this.controller) {
      this.controller.abort();
    }
  }

  sendErrorMsg() {
    this.alertTarget.innerHTML = this.alreadytextValue;
    this.alreadyClientTarget.value = false;
  }

  setAlreadyClientConnected() {
    this.alertTarget.innerHTML = null;
    this.#closeModal();
    this.markVisibilityTarget.classList.remove('hidden');
    this.modalVisibilityTarget.classList.add('hidden');
  }

  #closeModal() {
    this.dialog = document.querySelector("dialog[data-trigger-name='login_connect']");
    this.dialog.close();
  }
}
