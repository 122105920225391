import { Controller } from '@hotwired/stimulus';
import buildUrl from '@/shared/url.js';

// Select a city from a zip code.
// Connects to data-controller="zipcode-city"
export default class extends Controller {
  static targets = [
    'zipcode', // Zipcode input text field
    'cities', // Cities select dropdown field
  ]

  static values = {
    selected: String, // The value of the initially selected city
    textMethod: String, // The method to call on cities object to generate the option label
    valueMethod: String, // The method to call on cities object to generate the option value
  }

  clearCitiesOptionList() {
    this.citiesTarget.options.length = 0;
  }

  fillCitiesOptionList(cities) {
    if (cities.data.length >= 2) {
      this.citiesTarget.appendChild(new Option('Sélectionnez votre commune', '', true, true));
    }
    cities.data.forEach((city) => {
      const selected = (city.attributes[this.valueMethodValue] === this.selectedValue);
      const option = new Option(
        city.attributes[this.textMethodValue],
        city.attributes[this.valueMethodValue],
        selected,
        selected,
      );
      this.citiesTarget.appendChild(option);
    });
  }

  fillCitiesPlaceholder() {
    const placeholderOption = new Option('Entrez un code postal valide', '', true, true);
    placeholderOption.disabled = true;
    this.citiesTarget.appendChild(placeholderOption);
  }

  async updateCities() {
    const zipcode = this.zipcodeTarget.value;

    if (zipcode.match(/^\d{5}$/)) {
      const response = await fetch(buildUrl('/api/v1/cities', { zipcode }));
      if (response.ok) {
        const cities = await response.json();
        this.clearCitiesOptionList();
        this.fillCitiesOptionList(cities);
      }
    } else {
      this.clearCitiesOptionList();
      this.fillCitiesPlaceholder();
    }
  }

  updateCitiesForSubscription() {
    if (this.zipcodeTarget) {
      const zipcode = this.zipcodeTarget.value;
      if (zipcode.match(/^\d{5}$/)) {
        const submitter = document.createElement('button');
        submitter.dataset.turboAction = 'replace';
        // eslint-disable-next-line no-undef
        Turbo.navigator.submitForm(this.element.closest('form'), submitter);
      } else {
        this.citiesTarget.innerHTML = '<select name="select"></select>';
      }
    }
  }

  connect() {
    if (this.citiesTarget.value === '' && this.zipcodeTarget.value !== '') {
      this.updateCities();
    }
  }
}
